import './constituency.css';
import React, { useEffect } from 'react';
import Content from '../content/content.js'
import images from './images.js'

export default function Constituency(props) {

    useEffect(() => {
        document.title = "Patrick Pender - Wahlkreis"
     }, []);
    
    
    return (
        <React.Fragment>
            <div className="constituency-body">
                <div className="section-header">
                    <h1>Aus der Region. Für die Region.</h1>
                </div>
                <div className="content-image">
                    <img src={images.welcomeDialogImg} alt="wahlkreise" />
                </div>
                <div className="section-header">
                    <h1>Oersdorf</h1>
                </div>
                <div className="section-content">
                    <div className="text-container left">
                        <Content site="oersdorf" />
                    </div>
                    <div className="image-container position-right">
                        <img src={images.oersdorf} alt="oersdorf" />
                    </div>
                </div>
                <div className="section-header">
                    <h1>Kattendorf</h1>
                </div>
                <div className="section-content">
                    <div className="image-container position-left">
                        <img src={images.kattendorf} alt="kattendorf" />
                    </div>
                    <div className="text-container right">
                        <Content site="kattendorf" />
                    </div>
                </div>
                <div className="section-header">
                    <h1>Winsen</h1>
                </div>
                <div className="section-content">
                    <div className="text-container left">
                        <Content site="winsen" />
                    </div>
                    <div className="image-container position-right">
                        <img src={images.winsen} alt="winsen" />
                    </div>
                </div>  
                <div className="section-header">
                    <h1>Kisdorf</h1>
                </div>
                <div className="section-content">
                    <div className="image-container position-left">
                        <img src={images.kisdorf} alt="kisdorf" />
                    </div>
                    <div className="text-container right">
                        <Content site="kisdorf" />
                    </div>
                </div>  
                <div className="section-header">
                    <h1>Wakendorf II</h1>
                </div>
                <div className="section-content">
                    <div className="text-container left">
                        <Content site="wakendorfII" />
                    </div>
                    <div className="image-container position-right">
                        <img src={images.wakendorfII} alt="wakendorfII" />
                    </div>
                </div>  
                <div className="section-header">
                    <h1>Tangstedt</h1>
                </div>  
                <div className="section-content">
                    <div className="image-container position-left">
                        <img src={images.tangstedt} alt="tangstedt" />
                    </div>
                    <div className="text-container right">
                        <Content site="tangstedt" />
                    </div>
                </div>  
                <div className="section-header">
                    <h1>Norderstedt</h1>
                </div>  
                <div className="section-content">
                    <div className="text-container left">
                        <Content site="norderstedt" />
                    </div>
                    <div className="image-container position-right">
                        <img src={images.norderstedt} alt="norderstedt" />
                    </div>
                </div>
                <div className="section-header last">
                    <h1>für sie im wahlkreis aktiv</h1>
                </div>
            </div>
        </React.Fragment>
    );
}