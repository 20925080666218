import logo from '../images/brandLogo.svg';
import './header.css';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from '@material-ui/core';

export default function Header(props) {
    const { menu, socials } = props;

    return (
        <React.Fragment>
            <div className="header">
                <div className="branding">
                    <img src={logo} className="brand_logo" alt="logo" />
                </div>
                <div className="title">
                    <Link href={"/"}> Patrick <b>Pender</b></Link>
                </div>
                <div className="navigation">
                    {menu.map((item) => (
                        <Link
                            color="inherit"
                            noWrap
                            key={item.title}
                            variant="body2"
                            href={item.url}
                            className="menu-item"
                        >
                            {item.title}
                        </Link>
                    ))}
                </div>
                <div className="socials">
                    <div className="social-icons">
                        {socials.map((item) => (
                            <Link
                                href={item.url}
                                className="social-icon"
                                target="_blank"
                                rel="noopener"
                            >
                            {item.icon}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="donation">
                            <Link
                                key="donate-button-header"
                                href={"spenden"}
                                className="btn-primary btn-news"
                                target="_blank"
                                rel="noopener"
                            >
                            Spenden
                            </Link>
                </div>
            </div>
        </React.Fragment>
    );
  }


  Header.propTypes = {
    sections: PropTypes.array,
  };
