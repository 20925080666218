import './footer.css';
import React from 'react';
import { Link } from '@material-ui/core';

export default function Footer(props) {
    const { socials, links } = props;
    
    return (
        <React.Fragment>
            <div className="above">
                <div className="above-footer">
                    <div className="copyright">
                        Copyright © 2022 Patrick Pender
                        </div>
                    </div>
                <div className="above-footer-middle">
                    <div className="socials">
                        {socials.map((item) => (
                            <Link
                                href={item.url}
                                key={item.key}
                                className="social-icon"
                                target="_blank"
                                rel="noopener"
                            >
                            {item.icon}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="above-footer-right"></div>
                <div className="above-footer-right-out">
                    <div className="important-links">
                        {links.map((item) => (
                            <Link
                                href={item.url}
                                color="inherit"
                                key={item.key}
                                variant="body2"
                                className="footer-item"
                            >
                            {item.title}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}