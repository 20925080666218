import ReactMarkdown from "react-markdown";
import React, { useState, useEffect } from "react";

export default function Content(props) {
    const subSite = props
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`./posts/${subSite.site}.md`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });

    return (
        <React.Fragment>
            <div className="content">
                <div className="box">
                    <ReactMarkdown children={post ?? 'Loading...'} />
                </div>
            </div>
        </React.Fragment>
    );
}