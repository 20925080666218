import './donate.css';
import React, { useEffect }from 'react';
import { FormControl, OutlinedInput, InputAdornment, InputLabel }  from '@material-ui/core';
import images from '../content/images.js'
import Content from '../content/content.js'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default function Donate(props) {

    const [values, setValues] = React.useState({
        amount: "100.00",
      });

      const [test, setTest] = React.useState(false);

    const handleChange = (prop) => (event) => {
       setValues({ ...values, [prop]: event.target.value });
    };

    useEffect(() => {
        document.title = "Patrick Pender - Spenden"
     }, []);
    
    return (
        <React.Fragment>
            <div className="donate-body">
                <div className="donate-text-body">
                    <div className="donate-text">
                        <Content site="donate_top" />
                    
                    <FormControl className="donate-form" variant="outlined">
                        <OutlinedInput
                            className="example-amounts"
                            id="outlined-donate-amount-10"
                            type="number"
                            value="200.00"
                            disabled
                            onClick={
                                handleChange('amount')
                            }
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                        />
                        <OutlinedInput
                            className="example-amounts"
                            id="outlined-donate-amount-20"
                            type="number"
                            value="500.00"
                            disabled
                            onClick={
                                handleChange('amount')
                            }
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                        />
                        <OutlinedInput
                            className="example-amounts"
                            id="outlined-donate-amount-50"
                            type="number"
                            value="1000.00"
                            disabled
                            onClick={
                                handleChange('amount')
                            }
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                        />
                        </FormControl>
                        <FormControl className="amount-container" sx={{ m: 1 }}>
                        <InputLabel className="amount-label" htmlFor="outlined-donate-amount">Freien Betrag eingeben</InputLabel>
                        <OutlinedInput
                            className="amount-input"
                            id="outlined-donate-amount"
                            value={values.amount}
                            label="Freien Betrag eingeben"
                            type="number"
                            onChange={handleChange('amount')}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                            inputProps={{
                                'min': 0,
                                'max': 9999,
                            }}
                            required
                        />
                    </FormControl>
                    </div>
                    <div className="paypal-body">
                        <PayPalScriptProvider options={{ "client-id": "AZOJHWRIZ4RCFKDC892RQ-nWXUlWq98Vi6sY2W08m3bDl-QzrH5luqcPYlyFsXQAmhoytz10Hz3j_WtW", currency: "EUR"}}>
                            <PayPalButtons createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: parseFloat(values.amount),
                                            },
                                            description: "#TeamPender",
                                        },
                                    ],
                                });
                            }} forceReRender={{test}}
                            />
                        </PayPalScriptProvider>
                        {console.log(values.amount)}
                    </div>
                    <div className="donate-options">
                        <Content site="donate_options" />
                    </div>
                </div>
            </div>
            <div className="divider-image">
                <img src={images.divImgDonate} alt="divider" />
            </div>
                <div className="donate-text-body">
                    <Content site="donate_bottom" />
               </div>
        </React.Fragment>
    );
}