import React from 'react';
import './header.css';
import images from '../content/images.js'

export default function Head(props) {
    const { imageName } = props;

    return (
        <React.Fragment>
            <div className="head-image">
                <img src={images[imageName]} alt="header_image" />
            </div>
        </React.Fragment>
    );
}