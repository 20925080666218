import './App.css';
import Head from './header/head.js';
import Header from './header/header.js';
import Startseite from './content/startseite.js';
import About from './content/about.js'
import Content from './content/content.js'
import Contact from './content/contact.js'
import Constituency from './content/constituency.js'
import ContactForm from './content/contactForm.js'
import Donate from './content/donate.js'
import Footer from './footer/footer.js'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Facebook, Twitter, Instagram } from '@material-ui/icons';

const menu = [
  { title: "Startseite", url: '/' },
  { title: "Über mich", url: 'about' },
  { title: "Wahlkreis", url: 'wahlkreis' },
  { title: "Kontakt", url: 'kontakt' },
  { title: "Impressum", url: 'imprint' },
  { title: "Spenden", url: 'spenden' },
];

const socials = [
  { title: "Facebook", url: "https://www.facebook.com/patrickpender.de/", icon: <Facebook />, key: "facebook"},
  { title: "Instagram", url: "https://www.instagram.com/politiker.patrick.pender/", icon: <Instagram />, key: "instagram"},
  { title: "Twitter", url: "https://twitter.com/StadtverPender", icon: <Twitter />, key: "twitter" },
];

const links = [
  { title: "Impressum", url: "imprint", key: "imprint-footer" },
  { title: "Datenschutz", url: "privacy", key: "privacy-footer" },
];

function addRoute(type) {
  return (
    <Route exact path={'/'+type}>
      <Header menu={menu} socials={socials} />
      <Content site={type} />
      <Footer socials={socials} links={links} />
    </Route>
  )
}

function App() {
  return (
    <Router>
      <div className="App">
      <Switch>
        {addRoute("privacy")}
        {addRoute("1_news")}
        {addRoute("2_news")}
        {addRoute("3_news")}
        <Route exact path={'/imprint'}>
          <Head imageName="imprintHead"/>
          <Header menu={menu} socials={socials} />
          <Content site={'imprint'} />
          <Footer socials={socials} links={links} />
        </Route>
        <Route exact path={'/about'}>
          <Head imageName="aboutHead"/>
          <Header menu={menu} socials={socials} />
          <About />
          <ContactForm />
          <Footer socials={socials} links={links} />
        </Route>
        <Route exact path={'/spenden'}>
          <Head imageName="donateHead"/>
          <Header menu={menu} socials={socials} />
          <Donate />
          <Footer socials={socials} links={links} />
        </Route>
        <Route exact path={'/wahlkreis'}>
          <Head imageName="constituencyHead"/>
          <Header menu={menu} socials={socials} />
          <Constituency />
          <ContactForm />
          <Footer socials={socials} links={links} />
        </Route>
        <Route exact path={'/kontakt'}>
          <Head imageName="contactHead"/>
          <Header menu={menu} socials={socials} />
          <Contact />
          <ContactForm />
          <Footer socials={socials} links={links} />
        </Route>
        <Route path="/">
          <Startseite menu={menu} socials={socials} />
          <ContactForm />
          <Footer socials={socials} links={links} />
        </Route>
      </Switch>
      </div>
    </Router>
  );
}

export default App;
