// Team Images
import teamAG from '../images/persona/AlexanderGlowatzki.jpg';
import teamCG from '../images/persona/CedricGräper.jpg';
import teamCC from '../images/persona/ConradChen.jpg';
import teamHR from '../images/persona/HenningRohlfs.jpg';
import teamHB from '../images/persona/HenrykBartsch.jpg';
import teamLW from '../images/persona/LiaWodtke.jpg';
import teamMS from '../images/persona/MaikSkowronek.jpg';
import teamTM from '../images/persona/ThorbenMahlstedt.jpg';
import teamTL from '../images/persona/TristanLewin.jpg';
// Dialog Images
import welcomeDialogImg from '../images/welcomeDialog.png';
// Header Images
import aboutHead from '../images/headImg/about.png';
import contactHead from '../images/headImg/contact.png';
import donateHead from '../images/headImg/donate.png';
import imprintHead from '../images/headImg/imprint.png';
import constituencyHead from '../images/headImg/constituency.png';
// News Images
import newsImage0 from '../images/newsImage0.jpg';
import newsImage from '../images/newsImage.jpg';
import newsImage2 from '../images/newsImage2.jpg';
// Divider Images
import divImgDonate from '../images/contentImgDonate.png';
// Presse Images
import presseImgPortrait from '../images/presseImgPortrait.png'
import presseImgCduPender from '../images/presseImgCduPender.png'
import presseImgPenderNachKiel from '../images/presseImgPenderNachKiel.png'
// Events
import contactImgEvents from '../images/contactImgEvents.png'
// Wahlkreis Images
import oersdorf from '../images/constituency/oersdorf.png'
import kattendorf from '../images/constituency/kattendorf.png'
import kisdorf from '../images/constituency/kisdorf.png'
import norderstedt from '../images/constituency/norderstedt.png'
import tangstedt from '../images/constituency/tangstedt.png'
import wakendorfII from '../images/constituency/wakendorfII.png'
import winsen from '../images/constituency/winsen.png'
// About Me Images
import aktivImWahlkreis from '../images/about/aktiv_im_wahlkreis.png'
import stadtvertreter from '../images/about/stadtvertreter.png'
import freizeit from '../images/about/freizeit.png'
import landtagswahl2022 from '../images/about/landtagswahl2022.png'
import studium from '../images/about/studium.png'
import QaA from '../images/about/q_a.png'
import mitgliedschaften from '../images/about/mitgliedschaften.png'
// Goal Images
import goal0 from '../images/goals/000_g.png'
import goal1 from '../images/goals/001_g.png'
import goal2 from '../images/goals/002_g.png'
import goal3 from '../images/goals/003_g.png'
import goal4 from '../images/goals/004_g.png'
import goal5 from '../images/goals/005_g.png'
import goal6 from '../images/goals/006_g.png'
import goal7 from '../images/goals/007_g.png'
import goal8 from '../images/goals/008_g.png'
import goal9 from '../images/goals/009_g.png'
import goal10 from '../images/goals/010_g.png'
import goal11 from '../images/goals/011_g.png'
import goal12 from '../images/goals/012_g.png'

const Images = {
    teamAG,
    teamCC,
    teamCG,
    teamHR,
    teamHB,
    teamLW,
    teamMS,
    teamTM,
    teamTL,
    welcomeDialogImg,
    aboutHead,
    contactHead,
    donateHead,
    imprintHead,
    newsImage0,
    newsImage,
    newsImage2,
    divImgDonate,
    presseImgPortrait,
    presseImgCduPender,
    presseImgPenderNachKiel,
    contactImgEvents,
    constituencyHead,
    oersdorf,
    kattendorf,
    kisdorf,
    norderstedt,
    tangstedt,
    wakendorfII,
    winsen,
    aktivImWahlkreis,
    stadtvertreter,
    freizeit,
    landtagswahl2022,
    QaA,
    studium,
    mitgliedschaften,
    goal0,
    goal1,
    goal2,
    goal3,
    goal4,
    goal5,
    goal6,
    goal7,
    goal8,
    goal9,
    goal10,
    goal11,
    goal12
    }
export default Images