import './about.css';
import React, { useEffect } from 'react';
import images from './images.js'
import Content from './content';

export default function About(props) {

    useEffect(() => {
        document.title = "Patrick Pender - Über mich"
     }, []);
    
    
    return (
        <React.Fragment>
            <div className="about-me">
                <div className="main-heading">
                    Über mich
                </div>
                <div className="content">
                    <div className="row row-1">
                        <Content site="about_1"/>
                    </div>
                    <div className="row">
                        <img src={images.studium} className="full-size-img" alt="xd" />
                    </div>
                    <div className="row row-2">
                        <div className="column column-left">
                            <Content site="about_2"/>
                        </div>
                        <div className="column column-right">
                            <img src={images.stadtvertreter} className="column-image" alt="xd" />
                        </div>
                    </div>
                    <div className="row row-3">
                        <div className="column column-left">
                            <img src={images.aktivImWahlkreis} className="column-image" alt="xd" />
                        </div>
                        <div className="column column-right">
                            <Content site="about_3"/>
                        </div>
                    </div>
                    <div className="row row-4">
                        <img src={images.mitgliedschaften} className="full-size-img" alt="xd" />
                        <Content site="about_4"/>
                    </div>
                    <div className="row row-5">
                        <div className="column column-left">
                            <img src={images.freizeit} className="column-image" alt="xd" />
                        </div>
                        <div className="column column-right">
                            <Content site="about_5"/>
                        </div>
                    </div>
                    <div className="row row-6">
                        <div className="column column-left">
                            <Content site="about_6"/>
                        </div>
                        <div className="column column-right">
                            <img src={images.landtagswahl2022} className="column-image" alt="xd" />
                        </div>
                    </div>
                    <div className="row">
                        <img src={images.QaA} className="full-size-img" alt="xd" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}