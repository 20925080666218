import './contactForm.css';
import React from 'react';
import { Button, TextField, Checkbox }  from '@material-ui/core';

export default function ContactForm(props) {
    
    return (
        <React.Fragment>
            <div className="contact-form-box">
                <div className="sub-heading">
                    Schreiben Sie mir!
                </div>
                <form className="contact-form" action="https://formspree.io/f/mnqlkglq" method="POST">
                    <div className="field-row">
                        <TextField className="field-forename" variant="outlined" id="field-forename" name="forename" label="Vorname" required/>
                        <TextField className="field-location" variant="outlined" id="field-location" name="location" label="Wohnort" required/>
                    </div>
                        <TextField className="field-email" variant="outlined" id="field-email" name="email" label="E-Mail Adresse" required/>
                        <TextField className="field-subject" variant="outlined" id="field-subject" name="subject" label="Betreff" required/>
                        <TextField className="field-message" variant="outlined" id="field-message" name="message" multiline={true} rows="5" label="Ihre Nachricht" required/>
                    <div className="privacy-box">
                        <Checkbox className="privacy-checkbox" name="consent"/>
                        <div className="checkbox-text">
                            Ja, ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden für die Dauer des Landtagsmandats von Patrick Pender gespeichert. Sie haben das Recht, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Abs. f DSGVO erfolgt, Widerspruch einzulegen und diesen zu begründen. Der Widerspruch kann formfrei erfolgen und sollte möglichst via E-Mail an info@patrick-pender.de gerichtet werden. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung.
                        </div>
                    </div>
                    <Button className="btn-primary btn-checkbox" type="submit">
                        Abschicken
                    </Button>
                </form>
            </div>
        </React.Fragment>
    );
}