import './startseite.css';
import { Link }  from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, Menu, Close } from '@material-ui/icons';
import heroImage from '../images/heroImage.png';
import images from './images';
import contactImg from '../images/contactImage.png';
import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';

const deadline = "2022-05-08"

const importAll = (r) => r.keys().map(r);
const markdownFiles = importAll(require.context('../content/posts', false, /\.md$/))
  .sort();

export default function Startseite(props) {
    const { menu, socials } = props;
    const [posts, setPosts] = useState('');
    const [active, setActive] = useState(true);

    const total   = Date.parse(deadline) - Date.parse(new Date());
    const vSeconds = Math.floor( (total/1000) % 60 );
    const vMinutes = Math.floor( (total/1000/60) % 60 );
    const vHours   = Math.floor( (total/(1000*60*60)) % 24 );
    const vDays    = Math.floor( total/(1000*60*60*24) );
    
    const [minutes, setMinutes] = useState(vMinutes);
    const [seconds, setSeconds] =  useState(vSeconds);
    const [hours, setHours] = useState(vHours);
    const [days, setDays] = useState(vDays);

    const [slider, setSlider] =  useState(0);

    const [style, setStyle] = useState({display: 'block'});

    useEffect(()=>{
        let myInterval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        if(hours === 0) {
                            if(days === 0) {
                                clearInterval(myInterval)
                            } else {
                                setDays(days - 1);
                                setHours(23);
                            }
                        } else {
                            setHours(hours - 1);
                            setMinutes(59);
                        }
                    } else {
                        setMinutes(minutes - 1);
                        setSeconds(59);
                    }
                }
                if (minutes === 0) {

                } 
            }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        async function fetchData() {
            const posts = await Promise.all(markdownFiles.map((file) => fetch(file.default).then((res) => res.text())))
            .catch((err) => console.error(err))

            setPosts((state) => ({ ...state, posts }));
        };
        fetchData();
      }, []);

    const sliderNumber = (slide) => {
        if(slider < 0) {
            setSlider(12)
        }
        if(slider > 12) {
            setSlider(0)
        }
        if(slide < 0) {
            return (13-slide)
        }
        if(slide > 12) {
            return (slide-13)
        }
        return slide
    }

    return (
        <React.Fragment>
            <div className="startseite">
                <div className="hero">
                    <div className="hero-box">
                        <img src={heroImage} className="hero-image" alt="logo" />
                        <div className="navigation">
                            <div className="mobile-menu" 
                                onClick={e => { style.display === "none" ?
                                    setStyle({display: 'block'}) : setStyle({display: 'none'});
                                }}>
                                { style.display === "none" ? <Menu className="menu-icon"/> : <Close className="menu-icon"/> }
                            </div>
                            {menu.map((item) => (
                                <Link
                                    color="inherit"
                                    noWrap
                                    key={item.title}
                                    variant="body2"
                                    href={item.url}
                                    style={style}
                                    className="menu-item"
                                >
                                    {item.title}
                                </Link>
                            ))}
                            <div className="socials">
                                <div className="social-icons">
                                    {socials.map((item) => (
                                        <Link
                                            key={item.key}
                                            href={item.url}
                                            className="social-icon"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                        {item.icon}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="section-2">
                    <div className="news">
                        <div className="main-heading">
                            <h1>Aktuelles</h1>
                        </div>
                        <div className="news-content">
                            <div className="news-box">
                                <div className="image-box">
                                    <img src={images.newsImage0} className="news-image" alt="newsimage" />
                                </div>
                                <div className="news-text">
                                    <div className="left">
                                        <ReactMarkdown children={posts.posts ? posts.posts[0] : 'Loading...'} />
                                    </div>
                                </div>
                                <Link
                                    key={markdownFiles[0].default.slice(15, markdownFiles[0].default.length - 12)}
                                    href={markdownFiles[0].default.slice(15, markdownFiles[0].default.length - 12)}
                                    className="btn-primary btn-news"
                                >
                                    Mehr lesen...
                                </Link>
                            </div>
                            <div className="news-box">
                                <div className="image-box">
                                    <img src={images.newsImage} className="news-image" alt="newsimage" />
                                </div>
                                <div className="news-text">
                                    <div className="middle">
                                        <ReactMarkdown children={posts.posts ? posts.posts[1] : 'Loading...'} />
                                    </div>
                                </div>
                                <Link
                                    key={markdownFiles[1].default.slice(15, markdownFiles[1].default.length - 12)}
                                    href={markdownFiles[1].default.slice(15, markdownFiles[1].default.length - 12)}
                                    className="btn-primary btn-news"
                                >
                                    Mehr lesen...
                                </Link>
                            </div>
                            <div className="news-box">
                                <div className="image-box">
                                    <img src={images.newsImage2} className="news-image" alt="newsimage" />
                                </div>
                                <div className="news-text">
                                    <div className="left">
                                        <ReactMarkdown children={posts.posts ? posts.posts[2] : 'Loading...'} />
                                    </div>
                                </div>
                                <Link
                                    key={markdownFiles[2].default.slice(15, markdownFiles[2].default.length - 12)}
                                    href={markdownFiles[2].default.slice(15, markdownFiles[2].default.length - 12)}
                                    className="btn-primary btn-news"
                                >
                                    Mehr lesen...
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
  }
