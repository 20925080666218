import './contact.css';
import React, { useEffect } from 'react';
import { Link }  from '@material-ui/core';
import images from './images.js'
import Content from '../content/content.js'

export default function Contact(props) {

    useEffect(() => {
        document.title = "Patrick Pender - Kontakt"
     }, []);
    
    
    return (
        <React.Fragment>
            <div className="contact-body">
                <div className="contact-wrapper">
                    <div className="images-wrapper">
                        <div className="main-heading">
                            <h1>Pressebilder</h1>
                        </div>
                        <div className="row first-row">
                            <div className="frame">
                                <img src={images.presseImgPortrait} className="column-image" alt="CDU-Portrait Patrick Pender" />
                                <div className="appearing-div">
                                    <p>Die Verwendung und Veröffentlichung des Bildes ist bei Nennung "Foto: Natascha Mundt" honorarfrei gestattet. (3419x3654px; 9,87 MB) </p>
                                    <Link
                                        className="btn-primary btn"
                                        href={images.presseImgPortrait}
                                        download="CDU-Portrait Patrick Pender.png"
                                    > Herunterladen
                                    </Link>
                                </div>
                            </div>
                            <div className="frame">
                                <img src={images.presseImgPenderNachKiel} className="column-image" alt="Patrick Pender 2022 nach Kiel" />
                                <div className="appearing-div">
                                    <p>Die Verwendung und Veröffentlichung des Bildes ist bei Nennung "Foto: Henning Rohlfs" honorarfrei gestattet. (1920x985px; 2,39 MB) </p>
                                    <Link
                                        className="btn-primary"
                                        href={images.presseImgPenderNachKiel}
                                        download="Patrick Pender 2022 nach Kiel.png"
                                    > Herunterladen
                                    </Link>
                                </div>
                            </div>
                            <div className="frame">
                                <img src={images.presseImgCduPender} className="column-image" alt="CDU-Landtagskandidat Patrick Pender" />
                                <div className="appearing-div">
                                    <p>Die Verwendung und Veröffentlichung des Bildes ist bei Nennung "Foto: Henning Rohlfs" honorarfrei gestattet. (4000x6000px; 36,9 MB) </p>
                                    <Link
                                        className="btn-primary"
                                        href={images.presseImgCduPender}
                                        download="CDU-Landtagskandidat Patrick Pender.png"
                                    > Herunterladen
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="usage-text">
                            <Content site="press" />
                        </div>
                    </div>
                </div>
                <div className="calendly-wrapper">
                    <div className="calendly-heading">
                        <h1>Buchen Sie eine Bürgersprechstunde per Zoom</h1>
                    </div>
                    <div className="calendly-inline-widget" data-url="https://calendly.com/patrickpender/15min"></div>
                    <div className="calendly-footer">
                        <h1>Oder für Ihr Anliegen:</h1>
                    </div>
                    <div className="other-events">
                        <img src={images.contactImgEvents} alt="other events divider"/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}